<template>
  <div class="management-list">
    <div class="loader" v-if="getLoadingAllLearners">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-if="!getLoadingAllLearners">
      <div
        v-if="(getAllRegisteredUser || []).length == 0"
        class="no-record-holder"
      >
        <div>
          <img src="@/assets/wrappers/norecord.svg" />
          <p>No enrollments found!</p>
        </div>
      </div>
      <div v-if="(getAllRegisteredUser || []).length > 0">
        <b-container fluid>
          <b-row class="mt-3 mb-4">
            <b-col cols="4">
              <f2f-input
                id="icon"
                placeholder="Find a learner"
                v-model="searchTerm"
            /></b-col>

            <b-col>
              <div class="float-right">
                <b-dropdown
                  v-if="disableButton == !isDisabled"
                  :disabled="!isDisabled"
                  variant="outline-primary"
                  text="Bulk action"
                >
                  <b-dropdown-item @click="markBulkCancel()"
                    >Cancel enrollment</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-table
          small
          borderless
          responsive
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          v-if="!getLoadingAllLearners"
          class="p-3"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-if="data.item.enrollment_status == 'Enrolled'"
              :id="data.item.id"
              v-model="checkboxBind"
              :value="data.item.id"
            >
            </b-form-checkbox>
          </template>
          <template #cell(full_name)="data">
            <p class="learner-text-bold">
              {{ data.item.full_name }}
            </p>
          </template>
          <template #cell(email)="data">
            <p class="learner-text">{{ data.item.email }}</p>
          </template>
          <template #cell(enrollment_status)="data">
            <p class="learner-text">{{ data.item.enrollment_status }}</p>
          </template>
          <template #cell(enrollment_date)="data">
            <p class="learner-text">
              {{ data.item.enrollment_date }}
            </p>
          </template>
          <template #cell(options)="data">
            <b-button
              :id="'popover' + data.item.id"
              class="b-transparent float-right"
              v-if="data.item.enrollment_status == 'Enrolled'"
            >
              <img src="@/assets/three-dots.svg" alt="" />
            </b-button>
            <b-popover
              :target="'popover' + data.item.id"
              triggers="focus"
              placement="top"
            >
              <div class="edit-option">
                <div @click="cancelEnrollment(data.item.id)">
                  Cancel enrollment
                </div>
                <div class="separator"></div>
                <div>Admit enrollment</div>
              </div>
            </b-popover>
          </template>
        </b-table>

        <f2f-pagination
          class="mr-1 mb-2"
          :total-rows="rows"
          v-mode="currentPage"
          @page="onPage"
        />
      </div>
      <f2f-modal
        :display="confirmCancel"
        @hide="onCancel"
        type="warning"
        title="Cancel enrolment?"
        body="Are you sure you want to cancel the enrolment for this learner(s)?"
      />

      <f2f-modal
        :display="cancelError"
        @hide="closeCancelModal"
        type="danger"
        title="Something went wrong."
        body="Sorry we were not able to perform your request please try again."
      />

      <b-modal
        id="cancel-modal"
        v-model="unableCancel"
        hide-footer
        hide-header
        centered
      >
        <CancelModal
          :session_id="currentSessionId"
          @error="onCancelError"
          @hideReason="hideReason"
          @reload="reloadUI"
          :actionType="actionType"
          :checkboxBind="checkboxBind"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CancelModal from "./CancelEnrollmentModal.vue";
export default {
  props: {
    classId: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      showmodal: false,
      perPage: 10,
      currentPage: 1,
      detailsShowing: "",
      currentSessionId: -1,
      confirmCancel: false,
      unableCancel: false,
      cancelError: false,
      checkboxBind: [],
      actionType: "single",
      searchTerm: "",
      disableButton: false,
      fields: [
        {
          key: "show_details",
          label: ""
        },
        {
          key: "full_name",
          label: "Learner name",
          thStyle: { width: "15%" }
        },
        {
          key: "email",
          label: "Email",
          thStyle: { width: "25%" }
        },
        {
          key: "enrollment_status",
          label: "Status",
          thStyle: { width: "35%" }
        },
        {
          key: "enrollment_date",
          label: "Enrollment date"
        },
        {
          key: "options",
          label: ""
        }
      ]
    };
  },
  components: {
    CancelModal
  },
  computed: {
    ...mapGetters(["getAllRegisteredUser", "getLoadingAllLearners"]),

    items() {
      return (this.getAllRegisteredUser || [])
        .filter((learner) => {
          if (this.searchTerm) {
            return learner.full_name
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase());
          } else {
            return true;
          }
        })
        .map((cls) => {
          return {
            full_name: cls.full_name,
            email: cls.email,
            enrollment_status: cls.enrollment_status,
            enrollment_date: this.formatDateByPattern(
              cls.enrollment_date,
              "DD/MM/YYYY"
            ),
            id: cls.id
          };
        });
    },
    rows() {
      return this.items.length;
    },
    isDisabled() {
      return this.checkboxBind[0] > 0;
    }
    // getFilterUser() {
    //   return this.items || [];
    // },
  },
  async mounted() {
    this.$store.dispatch("getRegisteredUser", {
      course_id: this.$route.params.id,
      class_id: this.$route.params.classId
    });
  },
  methods: {
    onPage(page) {
      this.perPage = page.perPage;
      this.currentPage = page.currentPage;
    },
    onCancelError() {
      this.cancelError = true;
    },
    async onCancel(userAction) {
      if (userAction) {
        try {
          this.confirmCancel = false;
          this.unableCancel = true;
        } catch {
          this.unableCancel = false;
        }
      }
      this.confirmCancel = false;
    },

    reloadUI() {
      this.$store.dispatch("getRegisteredUser", {
        course_id: this.$route.params.id,
        class_id: this.$route.params.classId
      });
    },

    cancelEnrollment(session_id) {
      this.currentSessionId = session_id;
      this.confirmCancel = true;
      this.actionType = "single";
    },
    hideReason(userAction) {
      this.unableCancel = false;
      userAction && this.reloadUI();
    },
    closeCancelModal() {
      this.cancelError = false;
    },
    async markBulkCancel() {
      this.actionType = "bulk";
      this.confirmCancel = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;
  .form-space {
    min-width: auto;
  }
}

.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;
}
.no-record-holder {
  height: 60vh;
  min-height: 300px;
  border: 1px solid #b2b2b2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  img {
    width: 80px;
    margin-bottom: 1rem;
  }
}
.loader {
  margin-top: 20vh;
  text-align: center;
}
::v-deep {
  thead {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #a0a4a8;
  }
}
::v-deep tbody {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

::v-deep .nav-tabs {
  font-size: 14px;
}
.management-list {
  min-height: 300px;
  border: 1px solid #b2b2b2;
  background: white;
}
input.b-form-input {
  border-radius: 10px;
}
.learner-text-bold {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #25282b;
}
.learner-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #52575c;
}
.learner-text:hover {
  color: #0043ac;
  cursor: pointer;
}

.dropdown {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0043ac;
}
::v-deep .btn-outline-primary {
  color: #0043ac;
  border-color: #0043ac;
}
::v-deep .btn-outline-primary:hover {
  background: #0043ac;
  color: white;
}
::v-deep .btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #0043ac;
  color: white;
}
.b-transparent {
  background: transparent;
  border: none;
  :focus {
    background: transparent;
  }
  img {
    position: relative;
    bottom: 14px;
  }
}
.btn-secondary:hover {
  background: none;
}
.btn-secondary:focus {
  background: none;
}
.edit-option {
  background: #ffffff;
  border-radius: 4px;
  border: none;

  .separator {
    border: 0.5px solid #e8e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
  }

  div {
    color: #52575c;
    font-size: 16px;
  }

  div:hover {
    color: #0043ac;
    cursor: pointer;
  }
}

.pagination-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #757575;
}
</style>
