<template>
  <div>
    <div class="f2f-management">
      <div class="f2f-management__container">
        <b-row>
          <b-col cols="12">
            <div class="f2f-management__head">
              <div class="ml-3">
                <div class="management-head__title">
                  Classes >
                  <a class="bread-link" @click="redirect"> Management </a> >
                  {{ (getClass || {}).title }}
                </div>
                <div class="management-head__section">Enrolled users</div>
              </div>
              <EnrollComponent
                :classId="$route.params.classId"
                v-if="canEnroll(getClass)"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4 m-0">
          <b-col cols="12">
            <UserList />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import UserList from "@/components/classes/management/UserList.vue";
import { mapGetters } from "vuex";
import EnrollComponent from "@/components/classes/management/EnrollUserComponent.vue";

export default {
  components: { UserList, EnrollComponent },
  computed: {
    ...mapGetters(["getClass"])
  },
  mounted() {
    this.$store.dispatch("getClassDetails", {
      courseId: this.$route.params.id,
      classId: this.$route.params.classId
    });
  },
  methods: {
    redirect() {
      this.$router.push({ name: "management" });
    }
  }
};
</script>
<style lang="scss" scoped>
.bread-link {
  line-height: 24px;
  cursor: pointer;
  color: #4c4c4c;
}

.f2f-management {
  .f2f-management__container {
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;
    padding-bottom: 60px;

    .f2f-management__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0rem;
      border-bottom: 0.5px solid #e8e8e8;

      .management-head__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #4c4c4c;
      }

      .management-head__section {
        font-weight: bold;
        font-size: 38px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #333333;
      }
    }
  }
}
</style>
