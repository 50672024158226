<template>
  <div class="modal-content">
    <div class="title">
      <div>Reason for cancelation</div>
      <img
        src="@/assets/wrappers/cross.svg"
        alt="close"
        @click="hideReason(false)"
      />
    </div>
    <div class="body">
      Please select reason from the list:
      <b-form-group v-slot="{ ariaDescribedby }">
        <ValidationObserver v-slot="{}">
          <ValidationProvider
            name="Fields"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-radio
              class="mt-4"
              v-model="reason.cancellation_type"
              name="Date conflicts for the user"
              value="DATE_CONFLICTS"
              >Date conflicts for the user
            </b-form-radio>
            <b-form-radio
              class="mt-2"
              v-model="reason.cancellation_type"
              :aria-describedby="ariaDescribedby"
              name="Not suitable location"
              value="UNSUITABLE_LOCATION"
            >
              Not suitable location
            </b-form-radio>
            <b-form-radio
              class="mt-2"
              v-model="reason.cancellation_type"
              :aria-describedby="ariaDescribedby"
              name="Not interested in the class/course"
              value="NOT_INTERESTED"
            >
              Not interested in the class/course
            </b-form-radio>

            <b-form-radio
              class="mt-2"
              v-model="reason.cancellation_type"
              :aria-describedby="ariaDescribedby"
              name="Other:"
              value="OTHERS"
            >
              Other:
              <input
                type="text"
                v-model="reason.cancellation_reason"
                name=""
                id=""
                v-if="reason.cancellation_type == '4'"
              />
            </b-form-radio>
            <span class="f2f-validation-text">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="buttons mt-5">
            <div class="cancel" @click="hideReason(false)">Dismiss</div>
            <b-button
              variant="warning"
              :disabled="invalidForm"
              @click="saveReason(true)"
              >Save</b-button
            >
          </div>
        </ValidationObserver>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("required", required);

export default {
  props: ["session_id", "actionType", "checkboxBind"],
  data() {
    return {
      reason: {
        cancellation_type: "",
        cancellation_reason: ""
      }
    };
  },
  computed: {
    invalidForm() {
      return this.reason.cancellation_type === "";
    }
  },
  methods: {
    async saveReason(userAction) {
      if (userAction) {
        const postData = {
          course_id: this.$route.params.id,
          class_id: this.$route.params.classId,
          session_id: this.session_id
        };
        let actionName = "cancelUserRegistration";
        if (this.actionType == "single") {
          postData.reason = this.reason;
        } else {
          actionName = "bulkCancelRegistration";
          postData.payload = {
            user_ids: this.checkboxBind,
            cancellation_type: this.reason.cancellation_type,
            cancellation_reason: this.reason.cancellation_reason
          };
        }
        this.saveReasonAPICall(userAction, postData, actionName);
      }
    },
    async saveReasonAPICall(userAction, postData, actionName) {
      try {
        await this.$store.dispatch(actionName, postData);
        this.$emit("hideReason", userAction);
        this.$store.dispatch("showNotification", {
          message: "Enrollment cancelled successfully!"
        });
      } catch (err) {
        this.$emit("error", {});
        this.$emit("hideReason", userAction);
      }
    },
    hideReason(userAction) {
      this.$emit("hideReason", userAction);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;
}
::v-deep .btn-warning {
  color: white;
}
.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;
  height: 360px;

  .title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #25282b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    img {
      height: 16px;
      cursor: pointer;
    }
  }
  .body {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    margin-bottom: 40px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    .cancel {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #a0a4a8;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .warning {
    border-left: 5px solid #ffaa15;
  }
}
</style>
